/* HelpContent css */
.help-content {
    padding: 40px;
    background-color: rgba(255, 255, 255, 0.7);
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    max-width: 500px;
    margin: 20px auto;
    color: #333; /* Set text color to a nice black */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
  }
  
  .help-content h2 {
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  .help-content h3 {
    font-size: 20px;
    margin-top: 20px;
    font-size: 24px;
    font-weight: bold;
    text-align: center;
  }
  
  .help-content p {
    font-size: 16px;
    line-height: 1.5;
    margin-bottom: 20px;
    text-align: center; /* Justify the text */
  }
  
  .help-content a {
    color: #4285f4;
    text-decoration: none;
    font-weight: bold;
  }
  
  .help-content a:hover {
    text-decoration: underline;
  }
  


/* Emphasized text */
.emphasized-text {
  font-weight: bold;
  color: #333;
}

/* Centered text */
.center-text {
  text-align: center;
}

/* Emoji */
.emoji {
  font-size: 24px;
  margin-right: 5px;
}
nav {
  background-color: rgba(248, 249, 250, 0.9); /* Background color with opacity */
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
  padding: 10px 0;
}

ul {
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0;
}

li {
  margin: 0 15px;
}

a {
  text-decoration: none;
  color: #333;
  font-weight: bold;
  transition: color 0.3s;
  font-size: 16px;
}

a:hover {
  color: #4285f4;
}

.logo {
  font-size: 24px;
  color: #4285f4;
}

.logo img {
  max-height: 24px;
  margin-right: 8px;
}

.span {
  margin-right: 4px;  /* Spacing between flag and menu text */
}

/* Add this rule to target buttons and remove the border */
button, input[type="button"], input[type="submit"] {
  background-color: None; /* Background color with opacity */
  box-shadow: None;
  border: none;
  outline: none; /* This will remove the blue outline that appears when the button is clicked. If you want to keep it for accessibility reasons, just remove this line */
  cursor: pointer; /* This will ensure the mouse cursor changes to a pointer when hovering over the button */
}

/* Target icons and remove the background */
i {
  background-color: transparent;
}
/* Footer.css */
.global-footer {
    background-color: rgba(0, 0, 0, 0.8);
    color: white;
    padding: 20px;
    text-align: center;
    position: relative;
    bottom: 0;
    width: 100%;
    margin-top: auto;
  }
  
  .global-footer p {
    margin: 0;
    font-size: 14px;
  }
  
  .global-footer a {
    color: #4285f4;
    text-decoration: none;
  }
  
  .heart-icon {
    color: #e74c3c;
  }
  
  
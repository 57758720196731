
.tab-list {
    display: flex;
    justify-content: center;
    background-color: #f4f4f4;
    border-radius: 10px 10px 0 0;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  }
  
  .tab-item {
    padding: 10px 20px;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
  }
  
  .tab-item.active {
    background-color: #4285f4;
  }
  
  .tab-content {
    padding: 20px;
    background-color: #f4f4f4;
    border-radius: 0 0 10px 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
.App {
  text-align: center;
  background-color: #f5f5f5;
  background-color: transparent;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  min-height: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

a {
  color: #FFF;
}
a:hover {
   color: #FFF
}

/* ExampleComponent.css or Global CSS */
body {
  margin: 0;
  padding: 0;
  /* background-image: url('anton-8q-U8X1zkvI-unsplash.jpg'); 
  background-color: rgba(0, 0, 0, 0.5); /* Half-opacity black background */
  color: #333; /* Set text color to a nice black */
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
}
